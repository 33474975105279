import { useState, useEffect, Fragment } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { jwtDecode } from "jwt-decode";

import {
  getUsers,
  getDevices,
  createUser,
  deleteUser,
  resetUserPassword,
} from "../service/service_handler";

export default function UserScreen() {
  const [roomName, setRoomName] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [noData, setNoData] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [devices, setDevices] = useState([]);
  const [open, setOpen] = useState(false);
  const [openUserAdd, setOpenUserAdd] = useState(false);
  const [deviceIdText, setDeviceIdText] = useState("");
  const [noRoomData, setNoRoomData] = useState(false);
  const [isNewRoom, setIsNewRoom] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [userName, setUserName] = useState("");
  const [passwordTest, setPasswordText] = useState("");
  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState("");
  const [orgCode, setOrgCode] = useState("");
  const [roles, setRoles] = useState([]);
  const [rolesReq, setRolesReq] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openReset, setOpenReset] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [userNameLoged, setUserNameLoged] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [allRoleSelected, setAllRoleSelected] = useState(false);

  const [error, setError] = useState("");

  // const validateEmail = (email) => {
  //   const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return re.test(email);
  // };

  // const rolesList = [
  //   { id: 1, label: "add-device", checked: false },
  //   { id: 2, label: "add-user", checked: false },
  //   { id: 3, label: "add-spectrum", checked: false },
  //   { id: 4, label: "edit-spectrum", checked: false },
  //   { id: 5, label: "add-routines", checked: false },
  //   { id: 6, label: "edit-routines", checked: false },
  // ];

  const rolesList = [
    "add-device",
    "add-user",
    "add-spectrum",
    "edit-spectrum",
    "add-routines",
    "edit-routines",
    "manage-updates",
  ];

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const jwtdecoded = jwtDecode(localStorage.getItem("token"));
      const rolesarr = jwtdecoded.roles;
      rolesarr.pop();
      setRoles(rolesarr);
      // roles.pop();
      // setRoles(roles.slice(-1));
      setIsAdmin(jwtdecoded.orgCode == "SUPER_ADMIN" ? true : false);
      setOrgCode(jwtdecoded.orgCode);
      setOrgName(jwtdecoded.orgName);
      // setUserNameLoged(jwtdecoded.sub);
      console.log(
        "roles: " + JSON.stringify(jwtDecode(localStorage.getItem("token")))
      );
    }
    getUsers()
      .then(function (response) {
        setUsersData(response.data.users);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  }, []);

  const handleDropdownChange = (event) => {
    // setRoomName(event.target.value);
    getDevices()
      .then(function (response) {
        setRoomName(event.target.value);
        setDevices(response.data.spectrums);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleOpenNewRoom = () => {
    setOpen(true);
    setIsNewRoom(false);
  };

  const handleOpenNewUser = () => {
    setFirstName("");
    setUserName("");
    setPasswordText("");
    setRolesReq([]);
    setAllRoleSelected(false);
    setEmail("");
    // setOrgCode("");
    // setOrgName("");
    setOpenUserAdd(true);
  };

  const handleUserClose = () => {
    setOpenUserAdd(!openUserAdd);
  };

  const handleSave = () => {
    setOpen(!open);
  };

  const handleUsereAdd = () => {
    console.log("roles:" + rolesReq);
    // var validEmail = "";
    // if (!email) {
    //   setError("Email is required");
    // } else if (!validateEmail(email)) {
    //   setError("Invalid email address");
    // } else {
    //   setError("");
    //   validEmail = email;
    //   // alert(`Submitted Email: ${email}`);
    // }

    const user_data = {
      firstName: firstName,
      userName: userName,
      userPassword: passwordTest,
      orgCode: orgCode,
      orgName: orgName,
      email: email,
      roles: rolesReq,
    };
    if (userName != "" && orgCode != "" && passwordTest != "") {
      createUser(user_data)
        .then(function (response) {
          setOpenUserAdd(!setOpenUserAdd);
          updateUser();
          setopenSnackErrMsg("Successfully Added user ");
          setOpenSnack(true);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
            setNoData(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.message + " - Please try again later"
            );
            setOpenSnack(true);
            setNoData(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid Data!");
      setOpenSnack(true);
    }
  };

  const handleRoomChange = (event) => {
    getDevices()
      .then(function (response) {
        setDevices(response.data.devices);
        setRoomName(event.target.value);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleUserName = (event) => {
    setUserName(event.target.value);
  };

  const handlePasswordText = (event) => {
    setPasswordText(event.target.value);
  };

  const handleOrgCode = (event) => {
    setOrgCode(event.target.value);
  };

  const handleOrgName = (event) => {
    setOrgName(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
    validateEmail(event.target.value);
  };

  const validateEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(email);
    if (isValid) {
      console.log("Validation complete -> valid Email");
      setIsValidEmail(false);
    } else {
      console.log("Validation complete -> invalid Email");
      setIsValidEmail(true);
    }
  };

  const updateUser = () => {
    getUsers()
      .then(function (response) {
        setUsersData(response.data.users);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleRoleCheckBox = (event, index) => {
    setRolesReq((current) => [...current, event.target.value]);
    // const newArray = [...rolesReq, event.target.value];
    // setRolesReq(newArray);
    // console.log("role:" + event.target.value);
  };

  const handleClickOpenDelete = (row) => {
    setOpenDelete(true);
    setDeleteId(row.userId);
  };

  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
    setDeleteId("");
  };

  const handleConfirmedDelete = () => {
    deleteUser(deleteId)
      .then(function (response) {
        setopenSnackErrMsg("Successfully deleted user");
        setOpenSnack(true);
        updateUser();
        setOpenDelete(false);
        setDeleteId("");
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const handleClickOpenReset = (row) => {
    setUserName(row.userName);
    setOpenReset(true);
  };

  const handleCloseResetPopup = () => {
    setUserName("");
    setPasswordText("");
    setOpenReset(!openReset);
  };

  const handleConfirmedReset = () => {
    const user_data = {
      userName: userName,
      userPassword: passwordTest,
    };
    if (userName != "" && passwordTest != "") {
      resetUserPassword(user_data)
        .then(function (response) {
          setUserName("");
          setPasswordText("");
          setOpenReset(!openReset);
          updateUser();
          setopenSnackErrMsg("Password Reset Successfully");
          setOpenSnack(true);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
            setNoData(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
            setNoData(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid Data!");
      setOpenSnack(true);
    }
  };

  const handleCheckboxChange = (id) => {
    console.log("Checkbox changed for id:", id);
    // setCheckboxes((prevCheckboxes) => {
    //   // Toggle the checked state for the clicked checkbox
    //   const updatedCheckboxes = prevCheckboxes.map((checkbox) =>
    //     checkbox.id === id
    //       ? { ...checkbox, checked: !checkbox.checked }
    //       : checkbox
    //   );

    //   // Filter out the unchecked checkboxes
    //   // const checkedCheckboxes = updatedCheckboxes.filter(
    //   //   (checkbox) => checkbox.checked
    //   // );

    //   // Update the state with the labels of checked checkboxes
    //   // setRolesReq(checkedCheckboxes.map((checkbox) => checkbox.label));

    //   return updatedCheckboxes;
    // });
    // setCheckboxes((prevCheckboxes) => {
    //   // console.log("current id "+checkbox.id);
    //   const updatedCheckboxes = prevCheckboxes.map((checkbox) =>
    //     checkbox.id == id ? { ...checkbox, checked: true } : checkbox
    //   );

    //   return updatedCheckboxes;
    // });
  };

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const showNewRoom = () => {
    setIsNewRoom(!isNewRoom);
  };

  const selectAllRoles = () => {
    if (!allRoleSelected) {
      console.log("All role selected");
      setRolesReq(rolesList);
    } else {
      console.log("All role unselected");
      setRolesReq([]);
    }
    setAllRoleSelected(!allRoleSelected);
    // console.log("Role List: {}", JSON.stringify(rolesReq));
  };

  return (
    <div>
      <Typography
        sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
        variant="h5"
        component="div"
      >
        User Management
      </Typography>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />
      <Stack
        direction="row"
        spacing={2}
        mb={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Button variant="contained" onClick={handleOpenNewUser}>
          <AddIcon />
          ADD USER
        </Button>
        <Stack
          direction="row"
          spacing={2}
          mb={3}
          justifyContent="space-between"
          alignItems="center"
        ></Stack>
      </Stack>

      <Dialog open={openUserAdd} onClose={handleUserClose}>
        <DialogTitle>Add User</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleUserClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ minWidth: 250 }}>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="name"
              label="Name"
              type="name"
              fullWidth
              value={firstName}
              onChange={handleFirstName}
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="user_name"
              label="User Name"
              type="user_name"
              fullWidth
              value={userName}
              onChange={handleUserName}
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={passwordTest}
              onChange={handlePasswordText}
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="email"
              label="User Email"
              type="email"
              fullWidth
              value={email}
              error={isValidEmail}
              helperText={isValidEmail ? "Invalid email" : ""}
              onChange={handleEmail}
              // onBlur={validateEmail}
              variant="outlined"
            />
            {/* <TextField
              autoFocus
              margin="dense"
              size="small"
              id="email"
              label="User Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
              error={!!error}
              helperText={error}
              variant="outlined"
            /> */}
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="org_name"
              label="Organization Name"
              type="org_name"
              fullWidth
              value={orgName}
              onChange={handleOrgName}
              variant="outlined"
              InputProps={{
                readOnly: !isAdmin,
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="org_code"
              label="Organization Code"
              type="org_code"
              fullWidth
              value={orgCode}
              onChange={handleOrgCode}
              variant="outlined"
              InputProps={{
                readOnly: !isAdmin,
              }}
            />
          </Box>
          <FormControlLabel
            sx={{ ml: 0, mt: 1 }}
            control={<Checkbox onChange={selectAllRoles} />}
            label="Admin (All Roles)"
            labelPlacement="start"
          />

          <Typography sx={{ mt: 1 }} variant="subtitle1" gutterBottom>
            Select Roles:
          </Typography>
          <Box sx={{ ml: 2, mt: -1 }} fullWidth>
            <FormGroup aria-label="position" row align="center">
              {rolesList.map((role, index) => (
                <FormControlLabel
                  value={role}
                  control={
                    <Checkbox
                      disabled={allRoleSelected}
                      // checked={allRoleSelected}
                      // onChange={() => handleCheckboxChange(role.id)}
                    />
                  }
                  label={role}
                  // label={role.label}
                  labelPlacement="end"
                  onClick={(e) => handleRoleCheckBox(e, index)}
                />
              ))}
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserClose}>Cancel</Button>
          <Button onClick={handleUsereAdd}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openReset} onClose={handleCloseResetPopup}>
        <DialogTitle>Reset Password</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseResetPopup}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ minWidth: 250 }}>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="user_name"
              label="User Name"
              type="user_name"
              fullWidth
              value={userName}
              // onChange={handleUserName}
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="password"
              label="New Password"
              type="password"
              fullWidth
              value={passwordTest}
              onChange={handlePasswordText}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetPopup}>Cancel</Button>
          <Button onClick={handleConfirmedReset}>Reset</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please confirm to Delete ?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeletePopup}>No</Button>
          <Button onClick={handleConfirmedDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell align="right">Username</TableCell>
              <TableCell align="right">Organization Code</TableCell>
              <TableCell align="right">Organization Name</TableCell>
              <TableCell align="right">User Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersData.map((row) => (
              <TableRow
                key={row.firstName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.firstName}
                </TableCell>
                <TableCell align="right">{row.userName}</TableCell>
                <TableCell align="right">{row.orgName}</TableCell>
                <TableCell align="right">{row.orgCode}</TableCell>
                <TableCell align="right">
                  {row.status == 1 ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "success.light",
                        "&:hover": {
                          bgcolor: "success.light",
                        },
                      }}
                    >
                      ACTIVE
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "grey",
                        "&:hover": {
                          bgcolor: "grey",
                        },
                      }}
                    >
                      INACTIVE
                    </Button>
                  )}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleClickOpenDelete(row)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <Button onClick={() => handleClickOpenReset(row)}>
                    Reset Password
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
