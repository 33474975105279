import { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import { jwtDecode } from "jwt-decode";

import {
  getRooms,
  getSpectrum,
  getAllSpectrumByRoomId,
  deleteSpectrum,
} from "../service/service_handler";

export default function ColorSpectrum() {
  const [roomName, setRoomName] = useState({});
  const [roomNames, setRoomNames] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [noData, setNoData] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [spectrums, setspectrums] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [roles, setRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const jwtdecoded = jwtDecode(localStorage.getItem("token"));
      const rolesarr = jwtdecoded.roles;
      rolesarr.pop();
      setRoles(rolesarr);
      setIsAdmin(jwtdecoded.orgCode == "SUPER_ADMIN" ? true : false);
    }
    getRooms()
      .then(function (response) {
        setRoomNames(response.data.rooms);
        setRoomName(response.data.rooms[0]);

        getAllSpectrumByRoomId(response.data.rooms[0].roomId)
          .then(function (response) {
            setspectrums(response.data.spectrums);
          })
          .catch((error) => {
            console.log("service-error", error);
            if (!error.response) {
              setopenSnackErrMsg("Network error please try again");
              setOpenSnack(true);
              setNoData(true);
            } else {
              setopenSnackErrMsg(
                error.response.data.code + " - Please try again later"
              );
              setOpenSnack(true);
              setNoData(true);
            }
          });
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  }, []);

  const handleDropdownChange = (event) => {
    // setRoomName(event.target.value);
    getAllSpectrumByRoomId(event.target.value.roomId)
      .then(function (response) {
        setRoomName(event.target.value);
        setspectrums(response.data.spectrums);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleClickOpenDelete = (row) => {
    if (row.orgCode != "SUPER_ADMIN") {
      setOpenDelete(true);
      setDeleteId(row.spectrumControlId);
    } else {
      if (row.orgCode == "SUPER_ADMIN" && isAdmin) {
        setOpenDelete(true);
        setDeleteId(row.spectrumControlId);
      } else {
        setopenSnackErrMsg("Cannot Delete Admin Data");
        setOpenSnack(true);
      }
    }
  };

  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
    setDeleteId("");
  };

  const handleConfirmedDelete = () => {
    deleteSpectrum(deleteId)
      .then(function (response) {
        setopenSnackErrMsg("Successfully deleted Spectrum");
        setOpenSnack(true);
        updateSpectrumData();
        setOpenDelete(false);
        setDeleteId("");
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const updateSpectrumData = () => {
    getAllSpectrumByRoomId(roomName.roomId)
      .then(function (response) {
        setspectrums(response.data.spectrums);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSwitchChange = (row) => {};

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Typography
        sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
        variant="h5"
        component="div"
      >
        Spectrum Color Manager
      </Typography>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />
      <Stack
        direction="row"
        spacing={2}
        mb={3}
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* <Button variant="contained" disabled>
          <AddIcon />
          CREATE SPECTRUM
        </Button> */}
        <Stack
          direction="row"
          spacing={2}
          mb={3}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* <FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="demo-simple-select-label">Choose Room</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={roomName}
              label="Choose Room"
              onChange={handleDropdownChange}
              defaultChecked={roomName}
            >
              {noData ? (
                <em>
                  <MenuItem value="NaNa">No Data Found</MenuItem>
                </em>
              ) : (
                roomNames.map((rooms, index) => (
                  <MenuItem value={rooms}>{rooms.roomName}</MenuItem>
                ))
              )}
            </Select>
          </FormControl> */}
        </Stack>
      </Stack>

      <Dialog
        open={openDelete}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please confirm to Delete ?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeletePopup}>No</Button>
          <Button onClick={handleConfirmedDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Spectrum Name</TableCell>

              <TableCell align="right">Ratio Locked</TableCell>
              <TableCell align="right">Created On</TableCell>
              <TableCell align="right">Updated On</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spectrums.map((row) => (
              <TableRow
                key={row.spectrumName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.spectrumName}
                </TableCell>

                <TableCell align="right">
                  {row.lockRatio == 1 ? "True" : "False"}
                </TableCell>
                <TableCell align="right">
                  {dayjs(row.createdAt).format("lll")}
                </TableCell>
                <TableCell align="right">
                  {dayjs(row.updatedAt).format("lll")}
                </TableCell>
                <TableCell align="right">
                  {row.status == 1 ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "success.light",
                        "&:hover": {
                          bgcolor: "success.light",
                        },
                      }}
                    >
                      ACTIVE
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "grey",
                        "&:hover": {
                          bgcolor: "grey",
                        },
                      }}
                    >
                      INACTIVE
                    </Button>
                  )}
                </TableCell>
                {/* 
                <TableCell align="right">
                  <Switch
                    checked={row.status}
                    onClick={() => handleSwitchChange(row)}
                  />
                </TableCell> */}

                <TableCell align="right">
                  {
                    <Stack
                      direction="row"
                      spacing={1.5}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        size="small"
                        aria-label="close"
                        sx={{ ml: 1 }}
                        onClick={() => handleClickOpenDelete(row)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
