import { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Container from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import { jwtDecode } from "jwt-decode";

import {
  getRooms,
  getDevices,
  createDevice,
  addToRoom,
  deviceBlink,
  getDevicesFilter,
  deleteDevice,
} from "../service/service_handler";

export default function DeviceScreen() {
  const [roomName, setRoomName] = useState({});
  const [roomNames, setRoomNames] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [noData, setNoData] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [devices, setDevices] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDeviceAdd, setOpenDeviceAdd] = useState(false);
  const [deviceIdText, setDeviceIdText] = useState("");
  const [noRoomData, setNoRoomData] = useState(false);
  const [isNewRoom, setIsNewRoom] = useState(false);
  const [orgCode, setOrgCode] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [roomNameText, setRoomNameText] = useState("");
  const [deviceIdAct, setDeviceIdAct] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchOrgText, setSearchOrgText] = useState("");
  const [searchDeviceText, setSearchDeviceText] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = devices.filter((row) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      row.deviceName.toLowerCase().includes(lowerCaseQuery) ||
      row.deviceSerialNo.toLowerCase().includes(lowerCaseQuery)
    );
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const orgCodeUser = jwtDecode(localStorage.getItem("token")).orgCode;
      setIsAdmin(orgCodeUser == "SUPER_ADMIN" ? true : false);
      console.log(
        "roles: " + JSON.stringify(jwtDecode(localStorage.getItem("token")))
      );
    }

    getDevices()
      .then(function (response) {
        getRooms()
          .then(function (response) {
            setRoomNames(response.data.rooms);
            setRoomName(response.data.rooms[0]);
          })
          .catch((error) => {
            console.log("service-error", error);
            if (!error.response) {
              setopenSnackErrMsg("Network error please try again");
              setOpenSnack(true);
            } else {
              setopenSnackErrMsg(
                error.response.data.code + " - Please try again later"
              );
              setOpenSnack(true);
            }
          });

        const actieDeviceList = [];
        if (response.data.devices.length > 0) {
          const now = dayjs();
          response.data.devices.forEach((device) => {
            const lastOnline = dayjs(device.updatedAt);
            const diffInSeconds = now.diff(lastOnline, "second");

            if (diffInSeconds >= 50) {
              device.deviceStatus = 0;
            }
            actieDeviceList.push(device);
            if (diffInSeconds < 60) {
              device.lastOnline = `${diffInSeconds} sec ago.`;
            } else if (diffInSeconds < 3600) {
              device.lastOnline = `${Math.floor(diffInSeconds / 60)} min ago.`;
            } else if (diffInSeconds < 86400) {
              device.lastOnline = `${Math.floor(
                diffInSeconds / 3600
              )} hrs ago.`;
            } else {
              device.lastOnline = `${Math.floor(
                diffInSeconds / 86400
              )} days ago.`;
            }
          });
        }

        setDevices(response.data.devices);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  }, []);

  const handleDropdownChange = (event) => {
    // setRoomName(event.target.value);
    getDevices()
      .then(function (response) {
        setRoomName(event.target.value);
        setDevices(response.data.spectrums);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleClose = () => {
    setOpen(!open);
    setDeviceIdText("");
    setDeviceIdAct(0);
  };

  const handleOpenNewRoom = (event) => {
    setOpen(true);
    setIsNewRoom(false);
    console.log("rooms: " + roomNames);
    console.log("device-id: " + event);
    setDeviceIdText(event.deviceSerialNo);
    setDeviceIdAct(event.deviceId);
    setRoomNameText("");
  };

  const handleOpenNewDevice = () => {
    setOpenDeviceAdd(true);
  };

  const handleDeviceClose = () => {
    setDeviceIdText("");
    setDeviceName("");
    setDeviceIdAct(0);

    setOpenDeviceAdd(!openDeviceAdd);
  };

  const handleSave = () => {
    var device_data = {};
    if (isNewRoom) {
      device_data.isNewRoom = isNewRoom;
      device_data.roomName = roomNameText;
      device_data.deviceSerial = deviceIdText;
    } else {
      device_data.roomId = roomName.roomId;
      device_data.deviceSerial = deviceIdText;
    }

    addToRoom(deviceIdAct, device_data)
      .then(function (response) {
        updateDevice();
        setOpen(!open);
        setopenSnackErrMsg("Successfully Added device ");
        setOpenSnack(true);
        if (isNewRoom) {
          window.location.reload(false);
        }
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleDeviceAdd = () => {
    const device_data = {
      deviceName: deviceName,
      // orgCode: orgCode,
      deviceSerialNo: deviceIdText,
    };
    if (deviceName != "" && deviceIdText != "") {
      createDevice(device_data)
        .then(function (response) {
          updateDevice();
          setOpenDeviceAdd(!openDeviceAdd);
          setopenSnackErrMsg("Successfully Added device " + response.data.code);
          setOpenSnack(true);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
            setNoData(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
            setNoData(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid Data!");
      setOpenSnack(true);
    }
  };

  const handleRoomChange = (event) => {
    setRoomName(event.target.value);
  };

  const onDeviceNameChange = (event) => {
    setDeviceName(event.target.value);
  };

  const onDeviceIdChange = (event) => {
    setDeviceIdText(event.target.value);
  };

  const handleRoomNameText = (event) => {
    setRoomNameText(event.target.value);
  };

  const handleSearchByDevice = (event) => {
    setSearchDeviceText(event.target.value);
    const parms = {
      deviceName: event.target.value,
    };
    getDevicesFilter(parms)
      .then(function (response) {
        setDevices(response.data.devices);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleSearchByOrg = (event) => {
    setSearchOrgText(event.target.value);
    const parms = {
      orgCode: searchOrgText,
    };
    getDevicesFilter(parms)
      .then(function (response) {
        setDevices(response.data.devices);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleClickOpenDelete = (row) => {
    setOpenDelete(true);
    setDeleteId(row.deviceId);
  };

  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
    setDeleteId("");
  };

  const handleConfirmedDelete = (row) => {
    deleteDevice(deleteId)
      .then(function (response) {
        setopenSnackErrMsg("Successfully deleted user");
        setOpenSnack(true);
        updateDevice();
        setOpenDelete(false);
        setDeleteId("");
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const updateDevice = () => {
    getDevices()
      .then(function (response) {
        const actieDeviceList = [];
        if (response.data.devices.length > 0) {
          const now = dayjs();
          response.data.devices.forEach((device) => {
            const lastOnline = dayjs(device.updatedAt);
            const diffInSeconds = now.diff(lastOnline, "second");

            if (diffInSeconds >= 50) {
              device.deviceStatus = 0;
            }
            actieDeviceList.push(device);
            if (diffInSeconds < 60) {
              device.lastOnline = `${diffInSeconds} sec ago.`;
            } else if (diffInSeconds < 3600) {
              device.lastOnline = `${Math.floor(diffInSeconds / 60)} min ago.`;
            } else if (diffInSeconds < 86400) {
              device.lastOnline = `${Math.floor(
                diffInSeconds / 3600
              )} hrs ago.`;
            } else {
              device.lastOnline = `${Math.floor(
                diffInSeconds / 86400
              )} days ago.`;
            }
          });
        }

        setDevices(response.data.devices);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleBlink = (row) => {
    console.log("rooms: " + row.deviceSerialNo);
    deviceBlink(row.deviceSerialNo)
      .then(function (response) {
        setopenSnackErrMsg("Blinking device " + row.deviceSerialNo);
        setOpenSnack(true);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const handleSearchChange = (event) => {
    console.log(event.target.value);
    setSearchQuery(event.target.value);
  };

  // const columns = [
  //   { field: "deviceName", headerName: "Device Name", width: 70 },
  //   { field: "deviceId", headerName: "Device ID", width: 130 },
  //   { field: "lastName", headerName: "Device Temperature", width: 130 },
  //   { field: "lastName", headerName: "Fan Speed", width: 90 },
  //   { field: "lastName", headerName: "Room Name", width: 90 },
  //   { field: "lastName", headerName: "Organization", width: 90 },
  //   {
  //     field: "lastName",
  //     headerName: "Device Status",
  //     width: 90,
  //     sortable: false,
  //   },
  //   { field: "lastName", headerName: "Actions", width: 90, sortable: false },
  // ];

  // const rows = [{ id: 1, lastName: "Snow", firstName: "Jon", age: 35 }];

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const showNewRoom = () => {
    setIsNewRoom(!isNewRoom);
  };

  return (
    <div>
      <Typography
        sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
        variant="h5"
        component="div"
      >
        Device Manager
      </Typography>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />
      <Stack
        direction="row"
        spacing={2}
        mb={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Button variant="contained" onClick={handleOpenNewDevice}>
          <AddIcon />
          ADD DEVICE
        </Button>
        <Stack
          direction="row"
          spacing={2}
          mb={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Button variant="contained" size="small" onClick={updateDevice}>
            <RefreshIcon />
            &nbsp;Refresh
          </Button>
          {isAdmin && (
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="search_org"
              label="Search By Organization"
              type="search"
              onChange={handleSearchByOrg}
              variant="outlined"
            />
          )}
          <TextField
            autoFocus
            margin="dense"
            size="small"
            id="search_device"
            label="Search By Device"
            type="search"
            // onChange={handleSearchByDevice}
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
          />
        </Stack>
      </Stack>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Choose Room</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="device_id"
              label="Device ID"
              type="device_id"
              fullWidth
              value={deviceIdText}
              variant="outlined"
            />
          </Box>
          {!isNewRoom && (
            <div>
              <FormControl sx={{ width: 350, mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose Room
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={roomName}
                  label="Choose Room"
                  size="small"
                  onChange={handleRoomChange}
                >
                  {noRoomData ? (
                    <em>
                      <MenuItem value="NaNa">No Data Found</MenuItem>
                    </em>
                  ) : (
                    roomNames.map((rooms, index) => (
                      <MenuItem value={rooms}>{rooms.roomName}</MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <Typography id="input-slider" align="center" sx={{ m: 1 }}>
                Or
              </Typography>
              <Box textAlign="center" mb={1}>
                <Button
                  variant="contained"
                  size="small"
                  position="relative"
                  align="center"
                  justifyContent="center"
                  onClick={showNewRoom}
                >
                  <AddIcon />
                  &nbsp;New Room
                </Button>
              </Box>
            </div>
          )}

          {isNewRoom && (
            <div>
              <Box>
                <TextField
                  autoFocus
                  margin="dense"
                  size="small"
                  id="room_name"
                  label="Room Name"
                  type="room_name"
                  sx={{ width: 350, mt: 2 }}
                  value={roomNameText}
                  onChange={handleRoomNameText}
                  variant="outlined"
                />
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeviceAdd} onClose={handleDeviceClose}>
        <DialogTitle>Add Device</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDeviceClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="device_name"
              label="Device Name"
              type="device_name"
              fullWidth
              value={deviceName}
              onChange={onDeviceNameChange}
              variant="outlined"
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="device_id"
              label="Device ID"
              type="device_id"
              fullWidth
              value={deviceIdText}
              onChange={onDeviceIdChange}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeviceClose}>Cancel</Button>
          <Button onClick={handleDeviceAdd}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please confirm to Delete ?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeletePopup}>No</Button>
          <Button onClick={handleConfirmedDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Device Name</TableCell>
              <TableCell align="right">Device Id</TableCell>
              <TableCell align="right">Device Temperature</TableCell>
              <TableCell align="right">Fan Speed</TableCell>
              <TableCell align="right">Room Name</TableCell>
              <TableCell align="right">Organization</TableCell>
              <TableCell align="right">Device Status</TableCell>
              {/* <TableCell align="right">Last Online</TableCell> */}
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => (
              <TableRow
                key={row.deviceName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={{ width: "140px" }}
                  component="th"
                  scope="row"
                >
                  {row.deviceName}
                </TableCell>
                <TableCell style={{ width: "140px" }} align="right">
                  {row.deviceSerialNo}
                </TableCell>
                <TableCell style={{ width: "130px" }} align="right">
                  {row.deviceTemperature < -5
                    ? "NA"
                    : row.deviceTemperature + " ° C"}
                </TableCell>
                <TableCell style={{ width: "130px" }} align="right">
                  {row.fanSpeed} %
                </TableCell>
                <TableCell style={{ width: "130px" }} align="right">
                  {row.room ? row.room.roomName : "Not Added"}
                </TableCell>
                <TableCell style={{ width: "140px" }} align="right">
                  {row.orgCode}
                </TableCell>
                <TableCell style={{ width: "130px" }} align="right">
                  {row.deviceStatus == 1 ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "success.light",
                        "&:hover": {
                          bgcolor: "success.light",
                        },
                      }}
                    >
                      ONLINE
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "grey",
                        "&:hover": {
                          bgcolor: "grey",
                        },
                      }}
                    >
                      OFFLINE
                    </Button>
                  )}
                </TableCell>
                {/* <TableCell align="right">{row.lastOnline}</TableCell> */}
                <TableCell align="center">
                  {row.room ? (
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={() => handleOpenNewRoom(row)}
                    >
                      Change Room
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => handleOpenNewRoom(row)}
                    >
                      Add to Room
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ ml: 2 }}
                    onClick={() => handleBlink(row)}
                  >
                    BLINK
                  </Button>

                  <IconButton
                    size="small"
                    aria-label="close"
                    sx={{ ml: 1 }}
                    onClick={() => handleClickOpenDelete(row)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
