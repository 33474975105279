import { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import { jwtDecode } from "jwt-decode";

import {
  getRooms,
  getSpectrum,
  getAllSpectrumByRoomId,
  deleteSpectrum,
  deleteRoom,
  getAlerts,
} from "../service/service_handler";

export default function Alerts() {
  const [roomName, setRoomName] = useState({});
  const [roomNames, setRoomNames] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [noData, setNoData] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [spectrums, setspectrums] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [roles, setRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [alerts, setAlerts] = useState([]);
  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const jwtdecoded = jwtDecode(localStorage.getItem("token"));
      const rolesarr = jwtdecoded.roles;
      rolesarr.pop();
      setRoles(rolesarr);
      setIsAdmin(jwtdecoded.orgCode == "SUPER_ADMIN" ? true : false);
    }
    getAlerts()
      .then(function (response) {
        setAlerts(response.data.alerts);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  }, []);

  const updateSpectrumData = () => {
    getRooms()
      .then(function (response) {
        setRoomNames(response.data.rooms);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Typography
        sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
        variant="h5"
        component="div"
      >
        Alerts Manager
      </Typography>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Alert Id</TableCell>
              <TableCell align="right">Routine Name</TableCell>
              <TableCell align="right">Room Name</TableCell>
              <TableCell align="right">Routine Time</TableCell>
              <TableCell align="right">Alert Sent Time</TableCell>
              <TableCell align="right">Total Device</TableCell>
              <TableCell align="right">Unresponsive Devices</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alerts.map((row) => (
              <TableRow
                key={row.alertId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.alertId}
                </TableCell>

                <TableCell align="right">{row.routineName}</TableCell>
                <TableCell align="right">{row.roomName}</TableCell>

                <TableCell align="right">
                  {dayjs(row.routineTime * 1000).format("LT")}
                </TableCell>

                <TableCell align="right">
                  {dayjs(row.alertSentTime * 1000).format("lll")}
                </TableCell>

                <TableCell align="right">{row.totalDeviceSent}</TableCell>
                <TableCell align="right">{row.totalRespondedDevice}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
